import { Injectable, OnDestroy } from '@angular/core'
import moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { indicate } from '../../core/operators/indicate'
import { TransactionListItemWithOpenStatus } from '../../views/transactions/tx-overview-table/tx-overview-table.component'
import { BillingService } from '../administration/billing.service'
import { TransactionItemStatus, TransactionListItem, TransactionState } from '../../domain/transaction/transaction-listitem.model'
import { AdministrationService } from '../administration/administration.service'

@Injectable()
export class TransactionHelper implements OnDestroy {
  counter: Record<number, { [month: number]: number }> = {}
  loadingTxLockStatus$ = new BehaviorSubject<boolean>(false)
  private _txLimit: number

  constructor(private readonly _billing: BillingService, private readonly _administration: AdministrationService) {
    /**
     * Call this before all other functionality,
     * to prevent the first 20 or so transactions
     * all call given function simultaneously.
     */
    this.txLimit(true)
    this._administration.onSwitchedAdministration.subscribe(() => this.txLimit(true))
  }

  txLimit(forceFetch = false): number {
    if (this._txLimit && !forceFetch) {
      return this._txLimit
    }

    this._billing.numberOfAllowedTransactionPerMonth$.pipe(indicate(this.loadingTxLockStatus$)).subscribe((amount: number) => (this._txLimit = amount))

    return this._txLimit
  }

  /**
   * Reset the counter state
   * (i.e. 'OnDestroy' of component).
   */
  ngOnDestroy(): void {
    this.counter = {}
  }

  /**
   * Used to translate
   * 'trx_date' into moment.
   */
  txToMoment(tx: TransactionListItemWithOpenStatus): { [key in 'month' | 'year']: number } {
    return { month: moment(tx.trx_date).month(), year: moment(tx.trx_date).year() }
  }

  /**
   * Used to stop displaying
   * certain transactions to users.
   */
  txDisplayLimit(tx: TransactionListItemWithOpenStatus, mocks: number = 3): boolean {
    return tx.transaction_index_of_month > this.txLimit() + mocks
  }

  /**
   * Obtain data from the counter object.
   * Useful for accessing the counter object.
   * (from the template file).
   */
  getCountState(tx: TransactionListItemWithOpenStatus): number {
    return this.counter?.[this.txToMoment(tx).year]?.[this.txToMoment(tx).month]
  }

  /**
   * Split digits to show incremental
   * (optional) fancy counter animation.
   */
  splitDigitsForCounter(tx: TransactionListItemWithOpenStatus): string[] {
    return String(this.getCountState(tx)).split('')
  }

  /**
   * Count the remainer to show to end user.
   * Used to show 'x more tx' in overview.
   *
   * Tracked by both year and month,
   * for people who scroll real far :')
   */
  countRemainderLockedTxForMonth(tx: TransactionListItemWithOpenStatus, mocks: number = 3): void {
    const { month, year } = this.txToMoment(tx)

    if (tx.transaction_index_of_month > this.txLimit() + mocks) {
      this.counter?.[year]?.[month]
        ? // Increment if it does.
          this.counter[year][month]++
        : // Create object / keyvalue pair if it does not exist.
          (this.counter[year] = { ...this.counter[year], [month]: 1 })
    }
  }

  /**
   * Tx limit checking.
   */
  isTxLocked(tx: TransactionListItemWithOpenStatus): boolean {
    return tx.transaction_index_of_month > this.txLimit()
  }

  /**
   * Check if a transaction is upcoming.
   * @param tx TransactionListItemWithOpenStatus.
   */
  isTxUpcoming(tx: TransactionListItemWithOpenStatus): boolean {
    return tx.state === TransactionState.UPCOMING
  }

  /**
   * Check if a transaction is pending.
   * @param tx TransactionListItemWithOpenStatus.
   */
  isTxPending(tx: TransactionListItemWithOpenStatus): boolean {
    return tx.state === TransactionState.PENDING
  }

  /**
   * Check if a transaction is rejected.
   * @param tx TransactionListItemWithOpenStatus.
   */
  isTxRejected(tx: TransactionListItemWithOpenStatus): boolean {
    return tx.state === TransactionState.REJECTED
  }

  /**
   * Check if a transaction is cancelled.
   * @param tx TransactionListItemWithOpenStatus.
   */
  isTxCancelled(tx: TransactionListItemWithOpenStatus): boolean {
    return tx.state === TransactionState.CANCELLED
  }

  /**
   * Check if a transaction is released.
   * @param tx TransactionListItemWithOpenStatus.
   */
  isTxReleased(tx: TransactionListItemWithOpenStatus): boolean {
    return tx.state === TransactionState.RELEASED
  }

  /**
   * Check if a transaction is booked.
   * @param tx TransactionListItemWithOpenStatus.
   */
  isTxBooked(tx: TransactionListItemWithOpenStatus): boolean {
    return tx.fully_justified
  }

  /**
   * Check if a transaction is unreconciled.
   * @param tx TransactionListItemWithOpenStatus.
   */
  isTxUnreconciled(tx: TransactionListItemWithOpenStatus): boolean {
    return this.getTxStatus(tx) === TransactionItemStatus.UNRECONCILED
  }

  /**
   * Check if a transaction is bookable - (not rejected and not cancelled).
   * @param tx TransactionListItemWithOpenStatus.
   */
  isTxBookable(tx: TransactionListItemWithOpenStatus): boolean {
    return !this.isTxRejected(tx) && !this.isTxCancelled(tx) && !this.isTxReleased(tx)
  }

  isSwanTransaction(tx: TransactionListItem): boolean {
    return tx.mutation_code === 'SWAN'
  }

  /**
   * Get status label.
   */
  getTxStatus(tx: TransactionListItemWithOpenStatus): TransactionItemStatus {
    if (tx.fully_justified) {
      return TransactionItemStatus.BOOKED
    }

    switch (tx.state) {
      case TransactionState.CANCELLED:
        return TransactionItemStatus.CANCELLED

      case TransactionState.REJECTED:
        return TransactionItemStatus.REJECTED

      case TransactionState.RELEASED:
        return TransactionItemStatus.RELEASED

      default:
        return TransactionItemStatus.UNRECONCILED
    }
  }

  getTransactionStatusLabel(tx: TransactionListItemWithOpenStatus): string {
    switch (this.getTxStatus(tx)) {
      case TransactionItemStatus.BOOKED:
        return 'MAIN.BOOKED'

      case TransactionItemStatus.CANCELLED:
        return 'MAIN.CANCELED'

      case TransactionItemStatus.REJECTED:
        return 'MAIN.REJECTED'

      case TransactionItemStatus.RELEASED:
        return 'MAIN.RELEASED'

      case TransactionItemStatus.UNRECONCILED:
        return 'MAIN.UNRECONCILED'

      default:
        return ''
    }
  }
}
