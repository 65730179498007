import { Component, HostBinding, Input, ViewChild } from '@angular/core'
import { TellowNotification, TellowNotificationStyle } from '../../domain/notification/notification.model'

@Component({
  selector: 'tlw-notification',
  styleUrls: ['./tlw-notification.component.scss'],
  template: `
    <div
      class="notification-container {{ notification.style }}"
      [class.actionable]="notification.action"
      [class.drawer]="inDrawer"
      (click)="notification.action ? goToNotificationAcionOnMobileOrDrawer(notification.action.redirectAction) : null"
    >
      <div class="notification-container__info">
        <div *ngIf="inDrawer" class="notification-container__info__line {{ notification.style }} {{ notification.icon.color }}"></div>
        <div class="notification-container__info__icon {{ notification.icon.style }} {{ notification.icon.color }}">
          <svg-icon [src]="getIconUrl()" [color]="notification.icon.color" [svgStyle]="{ display: 'block' }"></svg-icon>
        </div>
        <div class="notification-container__info__text">
          <p class="title" *ngIf="notification.title">{{ notification.title | async }}</p>
          <p class="subtitle" [innerHTML]="notification.subTitle | async"></p>
        </div>
      </div>
      <div class="notification-container__actions" *ngIf="notification.action">
        <fnc-button
          (fncClick)="notification.action.redirectAction()"
          medium
          wide
          [class.light-purple]="isDefaultNotificationStyle"
          [class.white]="!isDefaultNotificationStyle"
        >
          {{ notification.action.actionText | async }}
        </fnc-button>
      </div>
    </div>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
      #tooltip="tippy"
      [tippy]="tpl"
      variation="popper"
      trigger="mouseenter"
      className="tlw-small"
      *ngIf="notification.dismiss"
      [class.white]="!isDefaultNotificationStyle"
    >
      <path
        fill="#5F6368"
        fill-rule="evenodd"
        d="M1.295.201A.747.747 0 0 0 .037.737a.751.751 0 0 0 .201.524l5.705 5.72-5.705 5.72a.75.75 0 0 0 .814 1.234.748.748 0 0 0 .243-.174L7 8.041l5.705 5.72A.747.747 0 0 0 14 13.237a.752.752 0 0 0-.238-.536l-5.705-5.72 5.705-5.72a.75.75 0 0 0-.534-1.26.747.747 0 0 0-.523.2L7 5.921 1.295.201Z"
        clip-rule="evenodd"
      />
    </svg>

    <ng-template #tpl let-hide>
      <a purple (click)="hideNotification(false)">{{ 'NOTIFICATIONS.REMIND_ME_LATER' | translate }}</a>
      <a red (click)="hideNotification(true)">{{ 'NOTIFICATIONS.DISMSS_FOREVER' | translate }}</a>
    </ng-template>
  `,
})
export class TlwNotificationComponent {
  @Input() notification: TellowNotification
  @Input() inDrawer: boolean = false
  @ViewChild('tooltip') tooltip: any

  @HostBinding('class.hidden') get checkHidden() {
    return this.hidden
  }

  public hidden: boolean = false

  get isDefaultNotificationStyle(): boolean {
    return this.notification.style === TellowNotificationStyle.DEFAUlT
  }

  goToNotificationAcionOnMobileOrDrawer(action: () => void): void {
    if (window.screen.width <= 640 || this.inDrawer) {
      void action()
    }
  }

  getIconUrl(): string {
    return `assets/icons/notifications/${this.notification.icon.name}.svg`
  }

  hideNotification(fully: boolean): void {
    this.notification.dismiss(fully)
    this.tooltip.hide()
    this.hidden = true
  }
}
