export const globals = {
  enableYearEnd: true,
  appName: 'Tellow',
  infoMailAddress: 'support@tellow.nl',
  infoPhoneNumber: '+31 85 888 50 57',
  inboundSupportNumber: '085 208 6962',
  supportAddress: {
    street: 'Danzigerkade 207',
    postalCode: '1013 AP',
    city: 'Amsterdam, Nederland',
  },
  animations: {
    defaultTiming: 'cubic-bezier(0, 0, 0.17, 1.08)',
    speed: {
      default: 200,
      halfSecond: 400,
      oneSecond: 1000,
      twoSeconds: 2000,
    },
  },
  belastingdienst: {
    name: 'Belastingdienst Apeldoorn',
    iban: 'NL86 INGB 0002 4455 88',
  },
  colors: {
    lightGrey: '#F3F3F3',
    darkNavy: '#0F1B33',
    white: 'white',
    blue: '#58C0FC',
    green: '#4BBC90',
    yellow: '#FCDD77',
    orange: '#FFB366',
    red: '#FF5F59',
    purple: '#955EBD',
    lightGreen: '#50E3C2',
    brown: '#8B572A',
    grey: '#9B9B9B',
  },
  // TODO: Double...
  colorPicker: {
    aqua: '#38CDDD',
    blue: '#30A2F2',
    darkNavy: '#384BA6',
    purple: '#9730B1',
    red: '#E9286F',
    orange: '#EF5F5E',
    turquoise: '#34AFA4',
    green: '#5AB65E',
    lightGreen: '#B8E986',
    yellow: '#F8E71C',
    white: '#F3F3F3',
    black: '#000',
  },
  elements: {
    ballSize: 40,
    gutterWidth: 20,
    menuShadeheight: 40,
  },
  defaultDebounceTime: 400,
  dateFormat: 'YYYY-MM-DD',
  fnsDateFormat: 'yyyy-MM-dd',
  displayDateFormat: 'DD-MM-YYYY',
  displayDateFormatWithTimestamp: 'dd-MM-yyyy - HH:mm',
  lyantheDateFormat: 'YYYY-MM-DDT00:00:00+01:00',
  fileDateFormat: 'YYYY/MM/DD',
  urls: {
    marketingWebsite: 'https://www.tellow.nl/',
    termsAndAgreement: 'https://www.tellow.nl/voorwaarden/',
    support: 'https://www.tellow.nl/support/',
    help: 'https://help.tellow.nl/',
    privacyStatement: 'https://www.tellow.nl/privacy/',
    status: 'https://status.tellow.nl/',
    loginPage: 'https://app.tellow.nl/inloggen',
    registerPage: 'https://app.tellow.nl/registreer',
    productPortal: 'https://portal.productboard.com/tellow',
    tellowStore: 'https://www.tellow.nl/store/',
    calendlySupportCall: 'https://calendly.com/tellow-support/belafspraak-met-tellow',
    intercomArticles: 'https://help.tellow.nl/nl/',
    advisorMarketplace: 'https://adviseurs.tellow.nl/',
    subscriptingInfo: 'https://help.tellow.nl/nl/collections/2867592-abonnement-instellingen-account',
    passkeysHelpCenter: 'https://help.tellow.nl/nl/articles/9631406-wat-zijn-passkeys',
    appStores: {
      apple: 'https://apps.apple.com/nl/app/tellow-voor-freelancers/id1187872148',
      google: 'https://play.google.com/store/apps/details?id=nl.tellow.app&hl=nl&gl=US',
    },
    agerasFinancePrivacyPolicy: 'https://tellow.nl/ageras-finance/privacy_policy_business_loans.pdf',
    feedback: 'https://product.tellow.nl/tabs/1-in-overweging',
    captchaPolicyUrl: 'https://policies.google.com',
  },
  sentry: {
    dsn: 'https://c18bf68def2449f7ae8750a3866c0c73@sentry.io/1401690',
  },
  banks: [
    {
      name: 'Rabobank',
      url: 'https://www.rabobank.nl/bedrijven/',
    },
    {
      name: 'ABN AMRO',
      url: 'https://www.abnamro.nl/nl/zakelijk/home.html',
    },
    {
      name: 'ING Bank',
      url: 'https://www.ing.nl/zakelijk/index.html',
    },
    {
      name: 'Knab',
      url: 'https://www.knab.nl/zakelijk',
    },
    {
      name: 'SNS Bank',
      url: 'https://www.snsbank.nl/zakelijk/home.html',
    },
    {
      name: 'Bunq',
      url: 'https://www.bunq.com/nl/business',
    },
    {
      name: 'Triodos',
      url: 'https://www.triodos.nl/zelfstandig-ondernemers',
    },
  ],
}
