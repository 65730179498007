import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core'
import { timer } from 'rxjs'
import { map } from 'rxjs/operators'
import { MenuService } from '../../layout-components/tlw-left-slideout-menu/menu.service'

@Component({
  selector: 'fnc-loader',
  styleUrls: ['./fnc-loader.component.scss'],
  template: `
    <main>
      <svg *ngIf="delayDisplayingLogoTimer$ | async" class="logo" viewBox="0 0 62 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36.7 0H24.67c0 5.1 1.04 9.93 2.92 14.34h-7.51A24.64 24.64 0 0 1 0 24.7v12.01c12.84 0 24.14-6.6 30.7-16.6a36.67 36.67 0 0 0 30.68 16.6v-12A24.72 24.72 0 0 1 36.69 0Z"
          fill="#5950EC"
        />
      </svg>

      <header *ngIf="showText && (delayDisplayingLogoTimer$ | async)">{{ message | translate }}&hellip;</header>
    </main>
  `,
})
export class FncLoaderComponent implements OnInit, OnDestroy {
  @Input() translatableMessage: string
  @Input() showText: boolean = true

  @HostBinding('class.full-page-size') @Input() fullPageSize: boolean = false

  // Delay displaying of logo by a short time, so you don't get a flash on short loads.
  protected delayDisplayingLogoTimer$ = timer(500).pipe(map((val) => Boolean(val === 0)))

  constructor(private readonly _menu: MenuService) {}

  ngOnInit() {
    this._menu.setLogoVisibility(false)
  }

  ngOnDestroy() {
    this._menu.setLogoVisibility(true)
  }

  get message(): string {
    return this.translatableMessage || 'MESSAGES.LOADING'
  }
}
