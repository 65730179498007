import { catchError, map, mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs'
import {
  TellowNotificationStyle,
  TellowNotification,
  TellowNotificationIconStyle,
  TellowNotificationIconColor,
  TellowNotificationName,
} from '../../domain/notification/notification.model'
import { BillingService } from '../administration/billing.service'
import { PaymentsService } from '../../logic/payments/payments.service'
import { BankService } from '../administration/bank.service'
import { StatsService } from '../dashboard/stats.service'
import { SubscriptionResponse } from '../../domain/billing/plan.model'
import { TranslateService } from '@ngx-translate/core'
import { UserService } from '../user/user.service'
import { User } from '../../domain/user/user.model'
import moment from 'moment'
import { SecurityService } from '../../core/security/security.service'
import { SegmentHelper } from '../external-services/segment.helper'
import { Router } from '@angular/router'
import { routerlinks } from '../../../environments/routerlinks/routerlinks-nl'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { environment } from '../../../environments/environment'
import { LaravelService } from '../banking/laravel.service'
import { AdministrationService } from '../administration/administration.service'
import { Administration } from '../../domain/administration/administration.model'
import { LegalFormType } from '../../domain/administration/administration-settings.model'
import { CardType, SwanCard } from '../../domain/swan/card.model'
import { BankAccountService } from '../administration/bankaccounts.service'
import { AdministrationBankAccount } from '../../domain/bankAccount/administration-bank-account.model'
import { TransactionService } from '../../logic/transactions/transaction.service'
import { TransactionSearchObject } from '../transactions/transaction-search-object'
import { TransactionListItem } from '../../domain/transaction/transaction-listitem.model'
import { EmailService } from '../user/email.service'
import { VerificationEmailModalSources } from '../../ui-components/tlw-email-verification-modal/tlw-email-verification-modal.component'
import { ActivationState } from '../../domain/swan/activation-state.model'
import { DismissedNotificationService } from './dismissed-notifications.service'

const AMOUNT_OF_NOTIFICATIONS_TO_DISPLAY_ON_HOME = 3

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private _loadingNotifications$ = new BehaviorSubject<boolean>(false)
  private _notifications: TellowNotification[] = []

  get notificationsToDisplayOnHomePage(): TellowNotification[] {
    return this._notifications.slice(0, AMOUNT_OF_NOTIFICATIONS_TO_DISPLAY_ON_HOME)
  }

  get notificationsToDisplayInDrawer(): TellowNotification[] {
    return this._isOnHomePage() ? this._notifications.slice(AMOUNT_OF_NOTIFICATIONS_TO_DISPLAY_ON_HOME) : this._notifications
  }

  get hasNotifications(): boolean {
    return this._notifications.length > 0
  }

  get hasNotificationsToDisplayInDrawer(): boolean {
    return this.notificationsToDisplayInDrawer.length > 0
  }

  get numberOfNotificationsToDisplayInDrawer(): number {
    return this.notificationsToDisplayInDrawer.length
  }

  get isLoadingNotfications$(): BehaviorSubject<boolean> {
    return this._loadingNotifications$
  }

  get noNotificationsNotification(): TellowNotification {
    return {
      name: TellowNotificationName.NO_NOTIFICATION,
      style: TellowNotificationStyle.DEFAUlT,
      subTitle: this._translate.get('NOTIFICATIONS.NO_ACTIONS_AND_MESSAGES'),
      icon: {
        style: TellowNotificationIconStyle.DEFAUlT,
        name: 'circled-check-mark',
        color: TellowNotificationIconColor.GREEN,
      },
    }
  }

  constructor(
    private readonly _dismissedNotifications: DismissedNotificationService,
    private readonly _billing: BillingService,
    private readonly _user: UserService,
    private readonly _administration: AdministrationService,
    private readonly _laravel: LaravelService,
    private readonly _payments: PaymentsService,
    private readonly _bank: BankService,
    private readonly _stats: StatsService,
    private readonly _translate: TranslateService,
    private readonly _security: SecurityService,
    private readonly _segment: SegmentHelper,
    private readonly _router: Router,
    private readonly _translateRoute: TranslateRouteService,
    private readonly _bankAccounts: BankAccountService,
    private readonly _transaction: TransactionService,
    private readonly _email: EmailService,
  ) {
    this.loadNotifications()
    this._billing.onBillingUpdate.subscribe(() => this.loadNotifications())
    this._administration.onSwitchedAdministration.subscribe(() => this.loadNotifications())
  }

  private _isOnHomePage(): boolean {
    const { url } = this._router

    return '/' === url.replace('#/', '')
  }

  dismissNotification(name: TellowNotificationName | ActivationState, fully: boolean): void {
    this._dismissedNotifications.dismissNotification$(name, fully).subscribe(() => {
      this._notifications.splice(
        this._notifications.findIndex((item) => item.name === name),
        1,
      )
      this._dismissedNotifications.clearCache()
      this.loadNotifications(false)
    })
  }

  /**
   * Loads all notifications, accounting for dimissed notifications.
   * @param {boolean} showLoading - Preset to true, can be used to refresh notifications without showing loading spinner. (Used after dismissing)
   */
  loadNotifications(showLoading = true): void {
    if (showLoading) this._loadingNotifications$.next(true)

    this._dismissedNotifications.getDismissedNotifications$().subscribe(() => this._fetchNotifications())
  }

  /**
   * Retrieves all available notifications as TellowNotification objects,
   * and stores them in the in their priority order.
   */
  private _fetchNotifications(): void {
    combineLatest([
      this._getBillingNotification$(),
      this._getBankOnboardingNotification$(),
      this._getBankCardReadyNotification$(),
      this._getBankOrderPhysicalCardNotification$(),
      this._getBankFirstTopUpNotification$(),
      this._getBankAccountSuspendedNotification$(),
      this._getOPPOnboardingNotification$(),
      this._getBankConsentExpiredNotifications$(),
      this._getTransactionsWithoutReceiptNotification$(),
      this._getBankUnfinishedOnboardingNotification$(),
      this._getBankStartIdentificationNotification$(),
      this._getVerifyEmailNotification$(),
      this._getDraftExpensesNotification$(),
      this._getBankReviewPendingNotification$(),
    ])
      .pipe(
        map(
          ([
            billingNotification,
            bankOnboardingNotification,
            bankCardReadyNotification,
            bankOrderPhysicalCardNotification,
            bankFirstTopUpNotification,
            bankAccountSuspendedNotification,
            OPPOnboardingNotification,
            bankConsentExpiredNotifications,
            transactionsWithoutReceiptNotification,
            bankUnfinishedOnboardingNotification,
            bankStartIdentificationNotification,
            verifyEmailNotification,
            draftExpensesNotification,
            bankReviewPendingNotification,
          ]: [
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification[],
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification | null,
            TellowNotification | null,
          ]) => {
            const notifications: TellowNotification[] = []

            // The priority order in which notifications are displayed is defined here
            if (billingNotification) notifications.push(billingNotification)
            if (bankOnboardingNotification) notifications.push(bankOnboardingNotification)
            if (bankUnfinishedOnboardingNotification) notifications.push(bankUnfinishedOnboardingNotification)
            if (bankStartIdentificationNotification) notifications.push(bankStartIdentificationNotification)
            if (bankCardReadyNotification) notifications.push(bankCardReadyNotification)
            if (bankOrderPhysicalCardNotification) notifications.push(bankOrderPhysicalCardNotification)
            if (bankFirstTopUpNotification) notifications.push(bankFirstTopUpNotification)
            if (bankAccountSuspendedNotification) notifications.push(bankAccountSuspendedNotification)
            if (OPPOnboardingNotification) notifications.push(OPPOnboardingNotification)
            bankConsentExpiredNotifications.forEach((bankConsentExpiredNotification) => notifications.push(bankConsentExpiredNotification))
            if (transactionsWithoutReceiptNotification) notifications.push(transactionsWithoutReceiptNotification)
            if (verifyEmailNotification) notifications.push(verifyEmailNotification)
            if (draftExpensesNotification) notifications.push(draftExpensesNotification)
            if (bankReviewPendingNotification) notifications.push(bankReviewPendingNotification)

            this._notifications = notifications
          },
        ),
        catchError((error) => {
          console.error(error)

          return of(null)
        }),
      )
      .subscribe(() => this._loadingNotifications$.next(false))
  }

  private _getBillingNotification$(): Observable<TellowNotification | null> {
    const billing = this._billing.getInfo()
    const user = this._user.user
    const hasBankAccount = this._laravel.hasBankAccount$

    return forkJoin([billing, user, hasBankAccount]).pipe(
      map(([{ type }, { created_at }, hasBankAccount]: [SubscriptionResponse, User, boolean]) => {
        const name = TellowNotificationName.INITIAL_UPSELL

        if (type === 'free' && !this._dismissedNotifications.allDismissedNotifications.find((value) => value === name)) {
          const createdAt = moment(created_at)
          const daysSinceCreatedAt = moment().diff(createdAt)
          const daysSinceCreatedAtInMillisec = moment.duration(daysSinceCreatedAt, 'milliseconds')

          const daysSinceFreemiumStart = Math.floor(daysSinceCreatedAtInMillisec.asDays())

          return this._makeNotification(
            name,
            TellowNotificationStyle.DEFAUlT,
            daysSinceFreemiumStart < 15
              ? this._translate.get('NOTIFICATIONS.TRIAL_PERIOD_REMAINING_SUBTITLE_50_COUPON')
              : daysSinceFreemiumStart < 30
              ? this._translate.get('NOTIFICATIONS.TRIAL_PERIOD_REMAINING_SUBTITLE_25_COUPON')
              : this._translate.get('NOTIFICATIONS.TRIAL_PERIOD_REMAINING_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.DEFAUlT,
              name: 'circled-crown',
              color: TellowNotificationIconColor.PURPLE,
            },
            undefined,
            {
              actionText: this._translate.get('NOTIFICATIONS.BUTTONS.SUBSCRIBE'),
              redirectAction: () => {
                if (daysSinceFreemiumStart < 30) {
                  this._segment.track('Discount Banner - Clicked')
                }

                if (hasBankAccount) {
                  const { index, account, billingSettings } = environment.routerLinks.settings

                  void this._translateRoute.navigate(`/${index}/${account}/${billingSettings}`)
                } else {
                  this._security.showUserPaywall()
                  this._security.saveUrlAfterPaywall(this._router.url)

                  void this._router.navigate([routerlinks.main.paywall])
                }
              },
            },
            (fully: boolean) => void this.dismissNotification(name, fully),
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getBankOnboardingNotification$(): Observable<TellowNotification | null> {
    const shouldSeeOpenBankNotification = this._laravel.shouldSeeOpenBankNotification$
    const bankOnboardingStatus = this._laravel.getOnboardingStatus$
    const hasBankAccount = this._laravel.hasBankAccount$
    const user = this._user.user
    const administration = this._administration.defaultAdministration

    return forkJoin([hasBankAccount, user, administration, shouldSeeOpenBankNotification, bankOnboardingStatus]).pipe(
      map(
        ([hasBankAccount, { date_of_birth }, { legal_form }, shouldSeeOpenBankNotification, bankOnboardingStatus]: [
          boolean,
          User,
          Administration,
          boolean,
          string,
        ]) => {
          const name = TellowNotificationName.BANK_START_ONBOARDING

          if (
            shouldSeeOpenBankNotification &&
            bankOnboardingStatus === 'none' &&
            !hasBankAccount &&
            date_of_birth === null &&
            legal_form !== LegalFormType.TYPE_BV &&
            !this._dismissedNotifications.allDismissedNotifications.find((value) => value === name)
          ) {
            return this._makeNotification(
              name,
              TellowNotificationStyle.COLORED,
              this._translate.get('NOTIFICATIONS.OPEN_TELLOW_BANK_SUBTITLE'),
              {
                style: TellowNotificationIconStyle.BIG,
                name: 'tellow-bank-card',
              },
              this._translate.get('NOTIFICATIONS.OPEN_TELLOW_BANK_TITLE'),
              {
                actionText: this._translate.get('NOTIFICATIONS.BUTTONS.OPEN'),
                redirectAction: () => {
                  void this._translateRoute.navigate(environment.routerLinks.banking.index)
                },
              },
              (fully: boolean) => void this.dismissNotification(name, fully),
            )
          } else {
            return null
          }
        },
      ),
      catchError(() => of(null)),
    )
  }

  private _getBankUnfinishedOnboardingNotification$(): Observable<TellowNotification | null> {
    const hasBankAccount = this._laravel.hasBankAccount$
    const bankOnboardingStatus = this._laravel.getOnboardingStatus$
    const user = this._user.user

    return forkJoin([hasBankAccount, user, bankOnboardingStatus]).pipe(
      map(([hasBankAccount, { date_of_birth }, bankOnboardingStatus]: [boolean, User, string]) => {
        const name = TellowNotificationName.BANK_UNFINISHED_ONBOARDING

        if (
          !hasBankAccount &&
          date_of_birth !== null &&
          bankOnboardingStatus !== 'Ongoing' &&
          !this._dismissedNotifications.allDismissedNotifications.find((value) => value === name)
        ) {
          return this._makeNotification(
            name,
            TellowNotificationStyle.COLORED,
            this._translate.get('NOTIFICATIONS.COMPLETE_TELLOW_BANK_ONBOARDING_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.DEFAUlT,
              name: 'circled-exclamation-mark',
              color: TellowNotificationIconColor.WHITE,
            },
            this._translate.get('NOTIFICATIONS.COMPLETE_TELLOW_BANK_ONBOARDING_TITLE'),
            {
              actionText: this._translate.get('NOTIFICATIONS.BUTTONS.COMPLETE'),
              redirectAction: () => {
                const { index, signup } = environment.routerLinks.banking
                void this._router.navigate([`${index}/${signup}`], { fragment: '/details' })
              },
            },
            (fully: boolean) => void this.dismissNotification(name, fully),
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getBankStartIdentificationNotification$(): Observable<TellowNotification | null> {
    const hasActiveBankAccount = this._laravel.hasActiveBankAccount$
    const bankOnboardingStatus = this._laravel.getOnboardingStatus$

    return forkJoin([hasActiveBankAccount, bankOnboardingStatus]).pipe(
      map(([hasActiveBankAccount, bankOnboardingStatus]: [boolean, string]) => {
        const name = TellowNotificationName.BANK_START_IDENTIFICATION

        if (
          !hasActiveBankAccount &&
          bankOnboardingStatus === 'Ongoing' &&
          !this._dismissedNotifications.allDismissedNotifications.find((value) => value === name)
        ) {
          return this._makeNotification(
            name,
            TellowNotificationStyle.COLORED,
            this._translate.get('NOTIFICATIONS.START_TELLOW_BANK_IDENTIFICATION_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.BIG,
              name: 'identity',
            },
            this._translate.get('NOTIFICATIONS.START_TELLOW_BANK_IDENTIFICATION_TITLE'),
            {
              actionText: this._translate.get('NOTIFICATIONS.BUTTONS.START'),
              redirectAction: () => {
                const { index, signup } = environment.routerLinks.banking
                void this._router.navigate([`${index}/${signup}`], { fragment: '/identification' })
              },
            },
            (fully: boolean) => void this.dismissNotification(name, fully),
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getBankReviewPendingNotification$(): Observable<TellowNotification | null> {
    const hasBankAccount = this._laravel.hasBankAccount$
    const bankOnboardingStatus = this._laravel.getOnboardingStatus$

    return forkJoin([hasBankAccount, bankOnboardingStatus]).pipe(
      map(([hasBankAccount, bankOnboardingStatus]: [boolean, string]) => {
        const name = TellowNotificationName.BANK_REVIEW_PENDING

        if (
          !hasBankAccount &&
          bankOnboardingStatus === 'Completed' &&
          !this._dismissedNotifications.allDismissedNotifications.find((value) => value === name)
        ) {
          return this._makeNotification(
            name,
            TellowNotificationStyle.COLORED,
            this._translate.get('NOTIFICATIONS.TELLOW_BANK_VERIFICATION_PENDING_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.DEFAUlT,
              name: 'hourglass',
              color: TellowNotificationIconColor.YELLOW,
            },
            this._translate.get('NOTIFICATIONS.TELLOW_BANK_VERIFICATION_PENDING_TITLE'),
            undefined,
            (fully: boolean) => void this.dismissNotification(name, fully),
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getBankCardReadyNotification$(): Observable<TellowNotification | null> {
    const hasIBAN = this._laravel.hasIBAN$
    const hasCards = this._laravel.hasCards$
    const isAccountClosedOrClosing = this._laravel.isAccountClosedOrClosing$

    return forkJoin([hasIBAN, hasCards, isAccountClosedOrClosing]).pipe(
      map(([hasIBAN, hasCards, isAccountClosedOrClosing]: [boolean, boolean, boolean]) => {
        const name = TellowNotificationName.BANK_CARD_READY

        if (hasIBAN && !isAccountClosedOrClosing && !hasCards && !this._dismissedNotifications.allDismissedNotifications.find((value) => value === name)) {
          return this._makeNotification(
            name,
            TellowNotificationStyle.COLORED,
            this._translate.get('NOTIFICATIONS.TELLOW_BANK_CARD_IS_READY_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.BIG,
              name: 'tellow-bank-card-virtual',
            },
            this._translate.get('NOTIFICATIONS.TELLOW_BANK_CARD_IS_READY_TITLE'),
            {
              actionText: this._translate.get('NOTIFICATIONS.BUTTONS.SEE_CARD'),
              redirectAction: () => {
                const { index, banking, cardSettings } = environment.routerLinks.settings
                void this._router.navigate([`${index}/${banking}/${cardSettings}`])
              },
            },
            (fully: boolean) => void this.dismissNotification(name, fully),
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getBankOrderPhysicalCardNotification$(): Observable<TellowNotification | null> {
    const shouldSeeOrderPhysicalCardNotification = this._laravel.shouldSeeOrderPhysicalCardNotification$
    const hasIBAN = this._laravel.hasIBAN$
    const hasCards = this._laravel.hasCards$
    const bankCards = this._laravel.getCards$
    const isAccountClosedOrClosing = this._laravel.isAccountClosedOrClosing$

    return forkJoin([shouldSeeOrderPhysicalCardNotification, hasIBAN, hasCards, bankCards, isAccountClosedOrClosing]).pipe(
      map(
        ([shouldSeeOrderPhysicalCardNotification, hasIBAN, hasCards, bankCards, isAccountClosedOrClosing]: [
          boolean,
          boolean,
          boolean,
          SwanCard[],
          boolean,
        ]) => {
          const name = TellowNotificationName.BANK_ORDER_PHYSICAL_CARD

          if (
            shouldSeeOrderPhysicalCardNotification &&
            hasIBAN &&
            hasCards &&
            !isAccountClosedOrClosing &&
            !bankCards.find((card) => card.type === CardType.VIRTUAL_AND_PHYSICAL) &&
            !this._dismissedNotifications.allDismissedNotifications.find((value) => value === name)
          ) {
            return this._makeNotification(
              name,
              TellowNotificationStyle.COLORED,
              this._translate.get('NOTIFICATIONS.TELLOW_BANK_ORDER_PHYSICAL_CARD_SUBTITLE'),
              {
                style: TellowNotificationIconStyle.BIG,
                name: 'tellow-bank-card',
              },
              this._translate.get('NOTIFICATIONS.TELLOW_BANK_ORDER_PHYSICAL_CARD_TITLE'),
              {
                actionText: this._translate.get('NOTIFICATIONS.BUTTONS.ORDER_CARD'),
                redirectAction: () => {
                  const { index, banking, cardSettings } = environment.routerLinks.settings
                  void this._router.navigate([`${index}/${banking}/${cardSettings}`], { queryParams: { type: 'physical' } })
                },
              },
              (fully: boolean) => void this.dismissNotification(name, fully),
            )
          } else {
            return null
          }
        },
      ),
      catchError(() => of(null)),
    )
  }

  private _getBankFirstTopUpNotification$(): Observable<TellowNotification | null> {
    const hasIBAN = this._laravel.hasIBAN$
    const hasCards = this._laravel.hasCards$
    const isAccountClosedOrClosing = this._laravel.isAccountClosedOrClosing$
    const bankAccounts = this._bankAccounts.getAllBankAccounts()

    return forkJoin([hasIBAN, hasCards, bankAccounts, isAccountClosedOrClosing]).pipe(
      mergeMap(([hasIBAN, hasCards, bankAccounts, isAccountClosedOrClosing]: [boolean, boolean, AdministrationBankAccount[], boolean]) => {
        const SwanBankAccount = bankAccounts.find((account) => account.is_swan)

        if (
          hasIBAN &&
          hasCards &&
          !isAccountClosedOrClosing &&
          SwanBankAccount &&
          SwanBankAccount.balance === 0 &&
          !this._dismissedNotifications.allDismissedNotifications.find((value) => value === TellowNotificationName.BANK_FIRST_TOP_UP)
        ) {
          const transactionSearchObject = new TransactionSearchObject()
          transactionSearchObject.bankAccountId = SwanBankAccount.id
          transactionSearchObject.filterjustified = false
          transactionSearchObject.limit = 1

          return this._transaction.getTransactions(transactionSearchObject)
        }

        const notEmptyList: TransactionListItem[] = [{} as TransactionListItem]

        return of(notEmptyList)
      }),
      map((transactions: TransactionListItem[]) => {
        if (transactions.length === 0) {
          const name = TellowNotificationName.BANK_FIRST_TOP_UP

          return this._makeNotification(
            name,
            TellowNotificationStyle.COLORED,
            this._translate.get('NOTIFICATIONS.TELLOW_BANK_FIRST_TOP_UP_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.BIG,
              name: 'top-up',
            },
            this._translate.get('NOTIFICATIONS.TELLOW_BANK_FIRST_TOP_UP_TITLE'),
            {
              actionText: this._translate.get('NOTIFICATIONS.BUTTONS.ADD_MONEY'),
              redirectAction: () => {
                const { index, topup } = environment.routerLinks.banking
                void this._router.navigate([`${index}/${topup}`])
              },
            },
            (fully: boolean) => void this.dismissNotification(name, fully),
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getBankAccountSuspendedNotification$(): Observable<TellowNotification | null> {
    return this._laravel.isAccountSuspended$.pipe(
      map((isAccountSuspended: boolean) => {
        const name = TellowNotificationName.BANK_ACCOUNT_SUSPENDED

        if (isAccountSuspended && !this._dismissedNotifications.allDismissedNotifications.find((value) => value === name)) {
          return this._makeNotification(
            name,
            TellowNotificationStyle.DEFAUlT,
            this._translate.get('NOTIFICATIONS.TELLOW_BANK_ACCOUNT_SUSPENDED_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.DEFAUlT,
              name: 'circled-exclamation-mark',
              color: TellowNotificationIconColor.YELLOW,
            },
            this._translate.get('NOTIFICATIONS.TELLOW_BANK_ACCOUNT_SUSPENDED_TITLE'),
            undefined,
            (fully: boolean) => void this.dismissNotification(name, fully),
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getOPPOnboardingNotification$(): Observable<TellowNotification | null> {
    const paymentsAvailable = this._payments.isPaymentsAvailable$
    const paymentsKYCNotFinished = this._payments.needsToFinishKYC$

    return forkJoin([paymentsAvailable, paymentsKYCNotFinished]).pipe(
      map(([paymentsAvailable, paymentsKYCNotFinished]: [boolean, boolean]) => {
        const name = TellowNotificationName.OPP_UNFINISHED_ONBOARDING

        if (paymentsAvailable && paymentsKYCNotFinished && !this._dismissedNotifications.allDismissedNotifications.find((value) => value === name)) {
          return this._makeNotification(
            name,
            TellowNotificationStyle.DEFAUlT,
            this._translate.get('NOTIFICATIONS.FINISH_PAYMENTS_ACCOUNT_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.DEFAUlT,
              name: 'triangle-exclamation-mark',
              color: TellowNotificationIconColor.RED,
            },
            this._translate.get('NOTIFICATIONS.FINISH_PAYMENTS_ACCOUNT_TITLE'),
            {
              actionText: this._translate.get('NOTIFICATIONS.BUTTONS.COMPLETE'),
              redirectAction: () => {
                const { index, onboarding } = environment.routerLinks.payments

                this._payments.hasCompletedBankAccountOnboardingStep$.subscribe((finishedBankAccount: boolean) => {
                  void this._translateRoute.navigateWithQueryParam(`${index}/${onboarding}`, null, { step: finishedBankAccount ? '3' : '2' })
                })
              },
            },
            (fully: boolean) => void this.dismissNotification(name, fully),
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getBankConsentExpiredNotifications$(): Observable<TellowNotification[]> {
    return this._bank.getAllRichAiiaAccounts().pipe(
      map((allAccounts) => allAccounts.filter((account) => account.entity.connectionRequiresUpdate === true)),
      map((accountsRequiringUpdate) => {
        const result: TellowNotification[] = []

        accountsRequiringUpdate.forEach((account) => {
          result.push(
            this._makeNotification(
              TellowNotificationName.CONSENT_EXPIRED,
              TellowNotificationStyle.DEFAUlT,
              this._translate.get('NOTIFICATIONS.BANK_CONSENT_SUBTITLE', { bankName: account.accountProvider.name }),
              {
                style: TellowNotificationIconStyle.DEFAUlT,
                name: 'triangle-exclamation-mark',
                color: TellowNotificationIconColor.RED,
              },
              this._translate.get('NOTIFICATIONS.BANK_CONSENT_TITLE'),
              {
                actionText: this._translate.get('NOTIFICATIONS.BUTTONS.RENEW'),
                redirectAction: () => {
                  const { settings } = environment.routerLinks
                  void this._translateRoute.navigate(`/${settings.index}/${settings.bank}/${settings.bankAccountConnections}`)
                },
              },
            ),
          )
        })

        return result
      }),
      catchError(() => of([])),
    )
  }

  private _getTransactionsWithoutReceiptNotification$(): Observable<TellowNotification | null> {
    return this._stats.expensesWithoutReceipt.pipe(
      map((numberOfTransactionsWithoutReceipt: number) => {
        if (numberOfTransactionsWithoutReceipt > 0) {
          return this._makeNotification(
            TellowNotificationName.TRANSACTIONS_WITHOUT_RECEIPT,
            TellowNotificationStyle.DEFAUlT,
            numberOfTransactionsWithoutReceipt > 1
              ? this._translate.get('NOTIFICATIONS.DRAFT_EXPENSES_SUBTITLE', { numberOfExpenses: numberOfTransactionsWithoutReceipt })
              : this._translate.get('NOTIFICATIONS.DRAFT_EXPENSE_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.DEFAUlT,
              name: 'triangle-exclamation-mark',
              color: TellowNotificationIconColor.RED,
            },
            undefined,
            {
              actionText: this._translate.get('NOTIFICATIONS.BUTTONS.RECONCILE'),
              redirectAction: () => {
                void this._translateRoute.navigate(environment.routerLinks.transactions.index, { filter: 'd', origin: 'notification' })
              },
            },
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getVerifyEmailNotification$(): Observable<TellowNotification | null> {
    return this._email.hasVerfiedEmail$.pipe(
      map((hasVerfiedEmail: boolean) => {
        if (!hasVerfiedEmail) {
          const name = TellowNotificationName.EMAIL_VERIFICATION

          return this._makeNotification(
            name,
            TellowNotificationStyle.DEFAUlT,
            this._translate.get('NOTIFICATIONS.VERIFY_EMAIL_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.DEFAUlT,
              name: 'circled-exclamation-mark',
              color: TellowNotificationIconColor.YELLOW,
            },
            this._translate.get('NOTIFICATIONS.VERIFY_EMAIL_TITLE'),
            {
              actionText: this._translate.get('NOTIFICATIONS.BUTTONS.VERIFY_EMAIL'),
              redirectAction: () => {
                void this._email.openEmailVerificationModal(VerificationEmailModalSources.Notification)
              },
            },
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _getDraftExpensesNotification$(): Observable<TellowNotification | null> {
    return this._stats.selfAnnotationRequiredInvoices.pipe(
      map((draftExpenses: number) => {
        if (draftExpenses > 0) {
          return this._makeNotification(
            TellowNotificationName.DRAFT_EXPENSES,
            TellowNotificationStyle.DEFAUlT,
            draftExpenses > 1
              ? this._translate.get('NOTIFICATIONS.TO_ANNOTATE_PLURAL_SUBTITLE', { numberOfFiles: draftExpenses })
              : this._translate.get('NOTIFICATIONS.TO_ANNOTATE_SINGULAR_SUBTITLE'),
            {
              style: TellowNotificationIconStyle.DEFAUlT,
              name: 'circled-exclamation-mark',
              color: TellowNotificationIconColor.YELLOW,
            },
            undefined,
            {
              actionText: this._translate.get('NOTIFICATIONS.BUTTONS.BOOK'),
              redirectAction: () => {
                void this._translateRoute.navigate(environment.routerLinks.expenses.index, { option: 'draft' })
              },
            },
          )
        } else {
          return null
        }
      }),
      catchError(() => of(null)),
    )
  }

  private _makeNotification(
    name: TellowNotificationName,
    style: TellowNotificationStyle,
    subTitle: Observable<string>,
    icon: {
      style: TellowNotificationIconStyle
      name: string
      color?: TellowNotificationIconColor
    },
    title?: Observable<string>,
    action?: {
      actionText: Observable<string>
      redirectAction: () => void
    },
    dismiss?: (forever: boolean) => void,
  ): TellowNotification {
    return {
      name,
      style,
      title,
      subTitle,
      icon,
      action,
      dismiss,
    }
  }
}
