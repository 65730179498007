import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { SubscriptionResponse, PlanData, PlanTiers, PlanId } from '../../domain/billing/plan.model'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { BillingService } from '../../logic/administration/billing.service'
import { FncModalComponent } from '../../ui-components/fnc-modal/fnc-modal.component'
import { FncConfirmModalComponent } from '../fnc-modal/fnc-confirm-modal/fnc-confirm-modal.component'
import { delay, map } from 'rxjs/operators'
import { TranslateRouteService } from '../../core/logic/i18n/translate-route.service'
import { environment } from '../../../environments/environment'
import { SegmentHelper } from '../../logic/external-services/segment.helper'

export type FullScreenUpsellData = {
  locationForTracking: string
  tierToUpgradeTo: PlanTiers
}

@Component({
  selector: 'tlw-full-screen-upsell-modal',
  styleUrls: ['tlw-full-screen-upsell-modal.component.scss'],
  templateUrl: 'tlw-full-screen-upsell-modal.component.html',
})
export class TlwFullScreenUpsellModalComponent implements OnInit {
  @Input() data: FullScreenUpsellData
  @Input() autoDisappearMessage: boolean
  @Input() showBankingImageForBasis: boolean = false

  @ViewChild(FncConfirmModalComponent, { static: true }) confirmUpsellModal: FncConfirmModalComponent
  @ViewChild('upsellModal', { static: true }) upsellModal: FncModalComponent

  private _currentPlanId: PlanId
  private _isOnBankingPlan: boolean

  get currentPlanIndex(): PlanId {
    return this._currentPlanId
  }

  set currentPlanIndex(index: PlanId) {
    this._currentPlanId = index
  }

  protected plans: Observable<PlanData[] | undefined> = combineLatest([this.billing.getPlans(), this.billing.getInfo()]).pipe(
    map(([plans, info]) =>
      plans?.data
        // Filter out freemium
        .filter((item) => item?.tier !== 0)
        // ...and tiers that are lower than to be upgraded to plans.
        .filter((item) => item?.tier >= this.data?.tierToUpgradeTo)
        // Take plans that are the same billing duration as the current plan + Compleet.
        .filter(
          (item) => item?.tier === 3 || item?.lockUserToThisPlanForMonths === ((info as SubscriptionResponse)?.data?.plan?.lockUserToThisPlanForMonths ?? 12),
        ),
    ),
  )

  /** lockUserToThisPlanForMonths, but local clone. */
  protected localLockUserToThisPlanForMonths: number = 0

  protected message$ = new BehaviorSubject<string>(null)

  protected readonly benefits: { [key in PlanTiers]?: string[] } = {
    1: ['STYLED_INVOICES', 'UNLIMITED_INVOICES', 'UNLIMITED_SCANNING', 'UNLIMITED_TRANSACTIONS', 'AUTOMATIC_REMINDERS'],
    2: ['EVERYTHING_FROM_BASIS', 'AUTOMATIC_VAT', 'UNLIMITED_QUOTES', 'PERSONAL_ADVICE', 'UNIMITED_USERS'],
    3: ['PERSONAL_BOOKKEEPER', 'PRIVATE_AND_BUSINESS', 'DIRECT_SUPPORT', 'ADVICE', 'QUARTERLY_CHECK', 'INCOME_TAX'],
  }

  protected get isCompleetPlan(): boolean {
    return this.currentPlanIndex === 'compleet_yearly_tier3' || this.currentPlanIndex === 'compleet_with_banking_yearly_tier3'
  }

  protected get isOnFreemium(): Observable<boolean> {
    return this.billing.getInfo().pipe(map((info: SubscriptionResponse) => info?.data?.plan?.planId === 'free_plan_endless'))
  }

  protected get getCurrentSelectedPlan(): Observable<PlanData | undefined> {
    return this.plans.pipe(map((plan) => plan?.filter((item) => item?.planId === this.currentPlanIndex)[0]))
  }

  protected get images(): { [key in PlanTiers]?: string } {
    return {
      1: this.showBankingImageForBasis ? 'banking-upsell.png' : 'expense-upsell.png',
      2: 'quotes-upsell.png',
      3: 'compleet-upsell.png',
    }
  }

  constructor(private readonly _router: TranslateRouteService, public billing: BillingService, private readonly _segment: SegmentHelper) {
    this.billing.getInfo(true).subscribe((info: SubscriptionResponse) => {
      this.localLockUserToThisPlanForMonths = info?.data?.plan?.lockUserToThisPlanForMonths || 0
    })
  }

  ngOnInit() {
    this.billing.getInfo().subscribe((info: SubscriptionResponse) => {
      this._isOnBankingPlan = this.billing.isBankingPlan(info.data.plan)
      this.currentPlanIndex = this._isOnBankingPlan ? 'compleet_with_banking_yearly_tier3' : 'compleet_yearly_tier3'

      this.setUpPlans()
    })

    // Automatically unset message after * seconds.
    if (this.autoDisappearMessage) {
      void this.message$
        .asObservable()
        .pipe(delay(6000))
        .subscribe(() => this.message$.next(null))
    }
  }

  private setUpPlans(): void {
    void this.plans
      .pipe(
        map((plans) =>
          plans
            .filter((plan) => plan?.lockUserToThisPlanForMonths === this.localLockUserToThisPlanForMonths)
            .filter((plan) => plan?.tier === this.data?.tierToUpgradeTo)
            .firstOrUndefined(),
        ),
      )
      .subscribe((item) => {
        this.currentPlanIndex = item?.planId ? item.planId : this._isOnBankingPlan ? 'compleet_with_banking_yearly_tier3' : 'compleet_yearly_tier3'
      })
  }

  protected setPlanIndex(index: PlanId) {
    this.currentPlanIndex = index
  }

  navigateToBillingOverview(): void {
    const { index, account, billingSettings } = environment.routerLinks.settings
    void this._router.navigate(`/${index}/${account}/${billingSettings}`)
  }

  public open(): void {
    this.upsellModal.open()
  }

  public openWithMessage(message: string): void {
    this.message$.next(message)
    this.upsellModal.open()
  }

  public close(): void {
    this.upsellModal.close()
  }

  public confirmUpsell(): void {
    this.isOnFreemium.subscribe((value) => {
      if (value) {
        void this.close()
        void this.onBillingConfirmation()
      } else {
        void this.confirmUpsellModal.open()
      }
    })
  }

  public planCompletePlanCall(): void {
    this._segment.track('Demo Call Upsell - Click')

    void window.open('https://calendly.com/tellow-compleet/compleet-informatie', '_blank')
  }

  protected onBillingConfirmation(): void {
    void this.getCurrentSelectedPlan.subscribe((plan) => {
      void this.billing.goToPaywallOrUpgrade(this.data?.locationForTracking, plan?.tier)
    })
  }
}
