export class TellowColor {
  static BLUE = new TellowColor('blue', '#1d82ef')
  static GREEN = new TellowColor('green', '#57e7ba')
  static ORANGE = new TellowColor('orange', '#ff7918')
  static RED = new TellowColor('red', '#ff6235')
  static PURPLE = new TellowColor('purple', '#4840bb')
  static YELLOW = new TellowColor('yellow', '#ffc31e')
  static LIGHTGREEN = new TellowColor('lightgreen', '#50E3C2')
  static BROWN = new TellowColor('brown', '#8B572A')
  static GREY = new TellowColor('grey', '#6E7685')

  constructor(private readonly _className: string, private readonly _solid: string, private readonly _transparent?: string) {}

  get className() {
    return this._className
  }

  get solid() {
    return this._solid
  }

  get transparant() {
    return this._transparent || this._solid
  }
}
