import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FncModalComponent } from '../fnc-modal.component'

@Component({
  selector: 'fnc-confirm-dialogue',
  template: `
    <fnc-modal #modal [withHigherZIndex]="true" [zIndex]="1234513" [size]="modalSize">
      <modal-header>
        {{ headerText | translate }}
      </modal-header>
      <modal-content>
        <p [innerHTML]="contentText | translate"></p>
      </modal-content>
      <modal-buttons style="display: inline-flex; gap: 0.5rem;">
        <fnc-button refactored-small class="{{ okColor }}" (fncClick)="ok()">{{ okKey | translate }}</fnc-button>
        <fnc-button refactored-small [style.order]="swap ? 2 : -1" class="{{ cancelColor }}" *ngIf="!singleButton" (fncClick)="cancel()">{{
          cancelKey | translate
        }}</fnc-button>
      </modal-buttons>
    </fnc-modal>
  `,
})
export class FncConfirmModalComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: FncModalComponent

  @Input() useHigherZIndex: boolean
  @Input('size') modalSize: 'small' | 'medium' | 'large' | 'xlarge' = 'medium'
  @Input() singleButton: boolean
  @Input() cancelColor: string = 'light-gray'
  @Input() okColor: string = 'purple'
  @Input() swap: boolean

  // i18n keys
  @Input('header') headerText: string = 'COMPONENTS.CONFIRMATION_MODAL.DEFAULT_TITLE'
  @Input('content') contentText: string = 'COMPONENTS.CONFIRMATION_MODAL.DEFAULT_TEXT'
  @Input() cancelKey: string = 'BUTTONS.CANCEL'
  @Input() okKey: string = 'BUTTONS.CONTINUE'

  @Output() okButton = new EventEmitter<any>()
  @Output() cancelButton = new EventEmitter<any>()

  private _cacheArguments: any
  private _preventNextCancelOnClose: boolean

  ngOnInit(): void {
    this.modal.visibleChange.subscribe((result) => {
      if (!result && !this._preventNextCancelOnClose) {
        this.cancelButton.emit()
      }

      this._preventNextCancelOnClose = false
    })
  }

  public get isVisible(): boolean {
    return this.modal.visible
  }

  // Executes given function
  public ok(): void {
    this.okButton.emit(this._cacheArguments)
    this.close()
  }

  public cancel(): void {
    this._preventNextCancelOnClose = true
    this.cancelButton.emit()
    this.close()
  }

  public close(): void {
    this._cacheArguments = null
    this.modal.close()
  }

  public open(args?: any) {
    this._cacheArguments = args
    this.modal.open()
  }
}
